import { MotiView } from 'moti'
import * as React from 'react'
import { AnimatePresence, Theme, YStack, YStackProps } from 'tamagui'
import { Hint } from './Hint'

const startingAnimation = { opacity: 0 }
const finalAnimation = { opacity: 1 }
const exitAnimation = { opacity: 0 }

export type FieldProps = YStackProps & {
  name: string
  children: React.ReactNode
  error?: string
  defaultError?: boolean
}

export function Field({ error, children, defaultError = true, ...otherProps }: FieldProps) {
  return (
    <Theme name={error !== undefined ? 'error' : undefined}>
      <YStack gap="$3" {...otherProps}>
        {children}
        <AnimatePresence>
          {error !== undefined ? (
            <MotiView from={startingAnimation} animate={finalAnimation} exit={exitAnimation}>
              <Hint>{error}</Hint>
            </MotiView>
          ) : defaultError ? (
            <Hint opacity={0}>None</Hint>
          ) : null}
        </AnimatePresence>
      </YStack>
    </Theme>
  )
}
