// since we don't want to track clicks on hint,
// we're importing the default SizableText instead of our version
import { styled } from 'tamagui'
import { SizableText } from './SizableText'

export const Hint = styled(SizableText, {
  variants: {
    unstyled: {
      false: {
        size: '$8',
        $gtXs: {
          size: '$9',
        },
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})
