import { useFormikContext } from 'formik'
import * as React from 'react'
import {
  CheckboxStyledContext,
  GetProps,
  Label,
  Checkbox as TCheckbox,
  XStack,
  withStaticProperties,
} from 'tamagui'
import { bugsnag } from '../utils/bugsnag'
import { analytics } from '../utils/firebase/analytics'
import { Field } from './Field'

type CheckboxProps = GetProps<typeof TCheckbox> & {
  label?: React.ReactNode
  startView?: React.ReactNode
  endView?: React.ReactNode
  onPress?: (checked: boolean | 'indeterminate') => unknown
  name: string
}

const CheckboxComponent = ({ label, onPress, name, ...rest }: CheckboxProps) => {
  const formik = useFormikContext<boolean | 'indeterminate'>()

  const { value, error, touched } = formik?.getFieldMeta?.<boolean | 'indeterminate'>(name) || {}

  const onCheckedChange = React.useCallback(
    (checked: boolean | 'indeterminate') => {
      onPress?.(checked)
      analytics.logEvent('type', {
        target: name,
        value: checked,
      })
      bugsnag().then((client) =>
        client.leaveBreadcrumb(
          'Type',
          {
            target: name,
            value: checked,
          },
          'process'
        )
      )
      formik?.setFieldTouched?.(name, true, false)
      formik?.setFieldValue?.(name, checked, true)
    },
    [onPress, name, formik]
  )

  return (
    <Field
      error={touched && error && (formik?.submitCount ?? 0) > 0 ? error : undefined}
      name={name}
    >
      <XStack gap="$4" ai="center">
        <TCheckbox
          onCheckedChange={onCheckedChange}
          {...rest}
          checked={value}
          br="$true"
          id={name}
          overflow="hidden"
        />
        {label !== undefined &&
          (React.Children.count(label) === 1 && typeof label === 'string' ? (
            <Label cursor="pointer" htmlFor={name}>
              label
            </Label>
          ) : (
            React.cloneElement(label as React.ReactElement, {
              htmlFor: name,
              cursor: 'pointer',
            })
          ))}
      </XStack>
    </Field>
  )
}

export const Checkbox = withStaticProperties(CheckboxComponent, {
  Indicator: TCheckbox.Indicator,
  Props: CheckboxStyledContext.Provider,
})
